<template>
  <div class="public-body">
    <div class="public-body__block public-body__block--decor">
      <a href="/">
        <img src="@/assets/img/login-logo.svg" alt="logo" />
      </a>
    </div>
    <div v-if="hash_valid && !submit_success" class="public-body__block">
      <h1 class="title">Анкета участника делегации «{{ delegation_name }}»</h1>
      <app-cells position="start">
        <div class="require-text"><sup>*</sup> - поля обязательные для заполнения</div>
      </app-cells>
      <form @submit.prevent="onCheckForm">
        <app-grid items="5" col="1">
          <template #item-1>
            <div class="color-panel">
              <app-cells position="start">
                <h2 class="title title--medium">Основные сведения</h2>
              </app-cells>
              <app-grid>
                <template #item-1>
                  <app-form-group required label="Статус участия">
                    <v-select
                      v-model="form.participation_status"
                      :reduce="(item) => item.id"
                      :options="participation_options"
                      :filterable="false"
                      :clearable="false"
                      :searchable="false"
                      label="name"
                      placeholder="Выберите значение"
                      class="select"
                      :class="{
                        'select--error': $v.form.participation_status.$error,
                      }"
                    >
                      <template #open-indicator>
                        <svg
                          width="18"
                          height="18"
                          fill="none"
                          class="open-indicator"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                          <path
                            d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                            fill="#fff"
                          ></path>
                        </svg>
                      </template>
                      <template #option="option">
                        <div class="select__item d-center">{{ option.name }}</div>
                      </template>
                      <template #selected-option="option">
                        <div class="selected d-center">{{ option.name }}</div>
                      </template>
                      <template #no-options>Ничего не найдено</template>
                    </v-select>
                    <template #error>
                      <div v-if="$v.form.participation_status.$dirty && !$v.form.participation_status.required">
                        Обязательное поле
                      </div>
                    </template>
                  </app-form-group>
                  <app-form-group required label="Фамилия" label-for="last_name">
                    <app-input
                      v-model="form.last_name"
                      id="last_name"
                      placeholder="Введите фамилию"
                      :error="$v.form.last_name.$error"
                      @change.native="$v.form.last_name.$touch()"
                    />
                    <template #error>
                      <div v-if="$v.form.last_name.$dirty && !$v.form.last_name.required">Обязательное поле</div>
                    </template>
                  </app-form-group>
                  <app-form-group required label="Имя" label-for="first_name">
                    <app-input
                      v-model="form.first_name"
                      id="first_name"
                      placeholder="Введите имя"
                      :error="$v.form.first_name.$error"
                      @change.native="$v.form.first_name.$touch()"
                    />
                    <template #error>
                      <div v-if="$v.form.first_name.$dirty && !$v.form.first_name.required">Обязательное поле</div>
                    </template>
                  </app-form-group>
                  <app-form-group label="Отчество" label-for="patronymic">
                    <app-input v-model="form.patronymic" id="patronymic" placeholder="Введите отчество" />
                  </app-form-group>
                  <app-form-group>
                    <app-cropper-web-cam v-model="form.crop" :aspectRatio="3 / 4" ref="cropper" />
                    <template #error>
                      <div v-if="$v.form.crop.$dirty && !$v.form.crop.required">Обязательное поле</div>
                    </template>
                  </app-form-group>
                </template>
                <template #item-2>
                  <app-form-group required label-for="birthday" label="Дата рождения">
                    <app-input
                      v-model="form.birthday"
                      v-mask="'99.99.9999'"
                      id="birthday"
                      placeholder="дд.мм.гггг"
                      autocomplete="off"
                      :error="$v.form.birthday.$error || $v.birthday_age.$invalid"
                      @paste.native.prevent
                      @input.native="onCheckBirthdayDate"
                    />
                    <template #error>
                      <div
                      v-if="member_min_year && !$v.form.birthday.$error"
                        class="form-group__age"
                      :class="{green: member_max_year >= minAge && member_min_year <= maxAge}"
                      >
                      <span v-if="member_max_year >= minAge && member_min_year <= maxAge"
                        >Возраст во время проведения фестиваля:</span
                        >
                      <span v-else>Недопустимый возраст участника:</span>
                      {{ member_min_year }}{{ member_min_year != member_max_year ? ` - ${member_max_year}` : '' }}
                      </div>
                      <div v-if="$v.form.birthday.$dirty && !$v.form.birthday.underscorePresent">
                        Заполните поле полностью
                      </div>
                      <div v-if="$v.form.birthday.$dirty && !$v.form.birthday.required">Обязательное поле</div>
                      <div v-if="$v.form.birthday.$dirty && !$v.birthday_age.between">Некорректная дата</div>
                    </template>
                  </app-form-group>
                  <app-form-group required label="Пол">
                    <app-cells position="start" :indent="false">
                      <app-radio
                        v-for="gender in gender_options"
                        :key="gender.id"
                        v-model.number="form.gender"
                        :value="gender.id"
                        :label="gender.name"
                        name="gender"
                      />
                    </app-cells>
                    <template #error>
                      <div v-if="$v.form.gender.$dirty && !$v.form.gender.required">Обязательное поле</div>
                    </template>
                  </app-form-group>
                  <app-form-group required label="Адрес электронной почты" label-for="email">
                    <app-input
                      v-model="form.email"
                      id="email"
                      type="email"
                      placeholder="Введите адрес электронной почты"
                      :error="$v.form.email.$error"
                      @change.native="$v.form.email.$touch()"
                    />
                    <template #error>
                      <div v-if="$v.form.email.$dirty && !$v.form.email.email">
                        Некорректный формат электронной почты
                      </div>
                      <div v-if="$v.form.email.$dirty && !$v.form.email.required">Обязательное поле</div>
                    </template>
                  </app-form-group>
                  <app-form-group label="Телефон" required>
                    <app-phone
                      v-model="form.phone"
                      :error="$v.form.phone.$dirty && (!$v.form.phone.required || form.phone === 0)"
                      @change.native="$v.form.phone.$touch()"
                      @paste.native.prevent
                    />
                    <template #error>
                      <div v-if="$v.form.phone.$dirty && !$v.form.phone.required">Обязательное поле</div>
                      <div v-if="$v.form.phone.$dirty && form.phone === 0 && $v.form.phone.required">
                        Неправильный формат номера
                      </div>
                    </template>
                  </app-form-group>
                  <app-form-group label='Ссылка на личный аккаунт "ВКонтакте"' label-for="vk" required>
                    <app-input v-model="form.vk_link" id="vk" type="text" placeholder="Введите ссылку" />
                    <template #error>
                      <div v-if="$v.form.vk_link.$dirty && !$v.form.vk_link.required">Обязательное поле</div>
                    </template>
                  </app-form-group>
                </template>
              </app-grid>
            </div>
          </template>
          <template #item-2>
          <div class="color-panel" v-show="member_min_year && member_min_year < 18">
              <app-cells position="start">
                <h2 class="title title--medium">Сведения о законном представителе</h2>
              </app-cells>
              <app-grid>
                <template #item-1>
                  <app-form-group required label="Тип законного представителя">
                    <app-cells position="start">
                      <app-radio
                        v-for="rep in representative_options"
                        :key="rep.id"
                        v-model.number="form.legal_representative_type"
                        :label="rep.name"
                        :value="rep.id"
                        name="legal_representative_type"
                      />
                    </app-cells>
                    <template #error>
                      <div
                        v-if="$v.form.legal_representative_type.$dirty && !$v.form.legal_representative_type.required"
                      >
                        Обязательное поле
                      </div>
                    </template>
                  </app-form-group>
                  <app-form-group label="Скан-копия паспорта представителя (основной разворот)" required>
                    <app-uploader v-model="form.legal_representative_passport" />
                    <template #error>
                      <div
                        v-if="
                          $v.form.legal_representative_passport.$dirty &&
                          !$v.form.legal_representative_passport.required
                        "
                      >
                        Обязательное поле
                      </div>
                    </template>
                  </app-form-group>
                  <app-form-group :label="legalRepresentationLabel" required>
                    <app-uploader v-model="form.legal_representative_confirmation" />
                    <template #error>
                      <div
                        v-if="
                          $v.form.legal_representative_confirmation.$dirty &&
                          !$v.form.legal_representative_confirmation.required
                        "
                      >
                        Обязательное поле
                      </div>
                    </template>
                  </app-form-group>
                </template>
                <template #item-2>
                  <app-form-group
                    label="Скан-копия информированного добровольного согласия на медицинские вмешательства"
                    required
                  >
                    <template #additional>
                      <app-tooltip>
                        <template #icon>
                          <icon-tooltip />
                        </template>
                        <template #content>
                          <button
                            class="tooltip__link"
                            @click="
                              onDownloadFile(
                                `${documents_url}members/example_med_agreement.docx`,
                                'Образец письменного заявления законного представителя о согласии на получение медицинской помощи'
                              )
                            "
                          >
                            Скачать образец письменного заявления законного представителя о согласии на получение
                            медицинской помощи
                          </button>
                        </template>
                      </app-tooltip>
                    </template>
                    <app-uploader v-model="form.legal_representative_medical" />
                    <template #error>
                      <div
                        v-if="
                          $v.form.legal_representative_medical.$dirty && !$v.form.legal_representative_medical.required
                        "
                      >
                        Обязательное поле
                      </div>
                    </template>
                  </app-form-group>
                  <app-form-group
                    label="Скан-копия письменного заявления законного представителя о согласии на участие в Фестивале"
                    required
                  >
                    <template #additional>
                      <app-tooltip>
                        <template #icon>
                          <icon-tooltip />
                        </template>
                        <template #content>
                          <button
                            class="tooltip__link"
                            @click="
                              onDownloadFile(
                                `${documents_url}members/example_participation_agreement.docx`,
                                'Образец письменного заявления законного представителя о согласии на участие в Фестивале'
                              )
                            "
                          >
                            Скачать образец письменного заявления законного представителя о согласии на участие в
                            Фестивале
                          </button>
                        </template>
                      </app-tooltip>
                    </template>
                    <app-uploader v-model="form.legal_representative_agree" />
                    <template #error>
                      <div
                        v-if="$v.form.legal_representative_agree.$dirty && !$v.form.legal_representative_agree.required"
                      >
                        Обязательное поле
                      </div>
                    </template>
                  </app-form-group>
                  <app-form-group
                    label="Приложение №5. Согласие на обработку персональных данных законного представителя"
                    required
                  >
                    <template #additional>
                      <app-tooltip>
                        <template #icon>
                          <icon-tooltip />
                        </template>
                        <template #content>
                          <button
                            class="tooltip__link"
                            @click="
                              onDownloadFile(
                                `${documents_url}members/example_pd_agreement.docx`,
                                'Приложение №5. Согласие на обработку персональных данных законного представителя'
                              )
                            "
                          >
                            Скачать образец приложения №5: согласие на обработку персональных данных законного
                            представителя
                          </button>
                        </template>
                      </app-tooltip>
                    </template>
                    <app-uploader v-model="form.legal_representative_pdata" />
                    <template #error>
                      <div
                        v-if="$v.form.legal_representative_pdata.$dirty && !$v.form.legal_representative_pdata.required"
                      >
                        Обязательное поле
                      </div>
                    </template>
                  </app-form-group>
                  <app-form-group
                    label="Приложение №6. Согласие на обработку персональных данных, разрешенных субъектом персональных данных для распространения законного представителя"
                    required
                  >
                    <template #additional>
                      <app-tooltip>
                        <template #icon>
                          <icon-tooltip />
                        </template>
                        <template #content>
                          <button
                            class="tooltip__link"
                            @click="
                              onDownloadFile(
                                `${documents_url}members/example_pd_legal_agreement.docx`,
                                'Приложение №6. Согласие на обработку персональных данных, разрешенных субъектом персональных данных для распространения законного представителя'
                              )
                            "
                          >
                            Скачать образец приложения №6: Согласие на обработку персональных данных, разрешенных
                            субъектом персональных данных для распространения законного представителя
                          </button>
                        </template>
                      </app-tooltip>
                    </template>
                    <app-uploader v-model="form.legal_representative_additional_agree" />
                    <template #error>
                      <div
                        v-if="
                          $v.form.legal_representative_additional_agree.$dirty &&
                          !$v.form.legal_representative_additional_agree.required
                        "
                      >
                        Обязательное поле
                      </div>
                    </template>
                  </app-form-group>
                </template>
              </app-grid>
            </div>
          </template>
          <template #item-3>
            <div class="color-panel">
              <app-cells position="start">
                <h2 class="title title--medium">{{ passportDataTextFillers.title }}</h2>
              </app-cells>
              <app-cells position="start">
                <app-radio
                v-show="member_min_year === 14 && isRussianParticipantWithAge"
                  v-for="opt in passport_options"
                  :key="opt.id"
                  v-model="passport_option"
                  :label="opt.label"
                  :value="opt.id"
                />
              </app-cells>
              <app-grid>
                <template #item-1>
                  <app-form-group required label="Гражданство">
                    <v-select
                      v-model.number="form.citizenship"
                      :reduce="(item) => item.id"
                      :options="citizenship_options"
                      @input="onChangeCitizenship"
                      :filterable="false"
                      :clearable="false"
                      :searchable="false"
                      label="name"
                      placeholder="Выберите значение"
                      class="select"
                      :class="{ 'select--error': $v.form.citizenship.$error }"
                    >
                      <template #open-indicator>
                        <svg
                          width="18"
                          height="18"
                          fill="none"
                          class="open-indicator"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                          <path
                            d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                            fill="#fff"
                          ></path>
                        </svg>
                      </template>
                      <template #option="option">
                        <div class="select__item d-center">{{ option.name }}</div>
                      </template>
                      <template #selected-option="option">
                        <div class="selected d-center">{{ option.name }}</div>
                      </template>
                      <template #no-options>Ничего не найдено</template>
                    </v-select>
                    <template #error>
                      <div v-if="$v.form.citizenship.$dirty && !$v.form.citizenship.required">Обязательное поле</div>
                    </template>
                  </app-form-group>
                  <app-form-group
                    :required="form.citizenship === 1"
                    :label="passportDataTextFillers.series"
                    label-for="passport_series"
                  >
                    <app-input
                      v-model="form.passport_series"
                      id="passport_series"
                      :type="form.citizenship === 1 && !isParticipantWithoutPassport ? 'number' : 'text'"
                      :placeholder="passportDataTextFillers.series_placeholder"
                      :error="$v.form.passport_series.$error"
                      @change.native="$v.form.passport_series.$touch()"
                      :readonly="!form.citizenship"
                    />
                    <template #error>
                      <div v-if="$v.form.passport_series.$dirty && !$v.form.passport_series.required">
                        Обязательное поле
                      </div>
                      <div v-if="$v.form.passport_series.$dirty && !$v.form.passport_series.minLength">
                        {{ passportDataTextFillers.series }} не может быть меньше 4 символов
                      </div>
                      <div v-if="$v.form.passport_series.$dirty && !$v.form.passport_series.maxLength">
                        {{ passportDataTextFillers.series }} не может быть больше 4 символов
                      </div>
                    </template>
                  </app-form-group>
                  <app-form-group
                    :required="form.citizenship === 1"
                    :label="passportDataTextFillers.number"
                    label-for="passport_number"
                  >
                    <app-input
                      v-model="form.passport_number"
                      id="passport_number"
                      :type="form.citizenship === 1 ? 'number' : 'text'"
                      :placeholder="passportDataTextFillers.number_placeholder"
                      :error="$v.form.passport_number.$error"
                      @change.native="$v.form.passport_number.$touch()"
                      :readonly="!form.citizenship"
                    />
                    <template #error>
                      <div v-if="$v.form.passport_number.$dirty && !$v.form.passport_number.required">
                        Обязательное поле
                      </div>
                      <div v-if="$v.form.passport_number.$dirty && !$v.form.passport_number.minLength">
                        {{ passportDataTextFillers.number }} не может быть меньше 6 цифр
                      </div>
                      <div v-if="$v.form.passport_number.$dirty && !$v.form.passport_number.maxLength">
                        {{ passportDataTextFillers.number }} не может быть больше 6 цифр
                      </div>
                    </template>
                  </app-form-group>
                  <app-form-group
                    v-if="form.citizenship === 1 && !isParticipantWithoutPassport"
                    :label="passportDataTextFillers.who_created"
                    required
                  >
                    <v-select
                      v-model="form.passport_issued_by"
                      :options="fms_options"
                      :filterable="false"
                      :clearable="false"
                      @search="onFMSSearch"
                      label="name"
                      placeholder="Введите значение"
                      class="select"
                      :class="{
                        'select--error': $v.form.passport_issued_by.$error,
                      }"
                    >
                      <template #open-indicator>
                        <svg
                          class="select__open-indicator"
                          xmlns="http://www.w3.org/2000/svg"
                          width="23"
                          height="23"
                          fill="none"
                        >
                          <path
                            clip-rule="evenodd"
                            d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                            stroke="#C7CFDD"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M20.12 20.12l-4.98-4.98"
                            stroke="#C7CFDD"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </template>
                      <template #no-options="{ search, searching }">
                        <div v-if="searching" class="select__noty">
                          По вашему запросу <em>{{ search }}</em> ничего не найдено.
                          <br />
                          Попробуйте еще раз.
                        </div>
                        <div v-else class="select__noty">
                          Для поиска подразделения выдавшего паспорт в справочнике, введите код подразделения в формате:
                          <span>260-012</span> или полное наименование
                          <span>ГУ МВД РОССИИ ПО СТАВРОПОЛЬСКОМУ КРАЮ</span>
                          и выберите значение из выпадающего списка.
                        </div>
                      </template>
                      <template #option="option">
                        <div class="select__item d-center">({{ option.code }}) {{ option.name }}</div>
                      </template>
                      <template #selected-option="option">
                        <div class="selected d-center">({{ option.code }}) {{ option.name }}</div>
                      </template>
                    </v-select>
                    <div
                    v-if="
                      form.passport_issued_by &&
                      form.passport_issued_by.name &&
                      form.passport_issued_by.name.length > 20
                    "
                      class="select__long"
                    >
                      {{ form.passport_issued_by && form.passport_issued_by.name }}
                    </div>
                    <template #error>
                      <div v-if="$v.form.passport_issued_by.$dirty && !$v.form.passport_issued_by.required">
                        Обязательное поле
                      </div>
                    </template>
                  </app-form-group>
                  <app-form-group
                    v-else-if="form.citizenship === 2 || form.citizenship === 3 || isParticipantWithoutPassport"
                    label-for="passport_fms_name"
                    :label="passportDataTextFillers.who_created"
                    required
                  >
                    <app-input
                      key="passport_issued_by"
                      v-model="form.passport_issued_by"
                      id="passport_fms_name"
                      placeholder="Введите кем выдан паспорт"
                      :error="$v.form.passport_issued_by.$error"
                    />
                    <template #error>
                      <div v-if="$v.form.passport_issued_by.$dirty && !$v.form.passport_issued_by.required">
                        Обязательное поле
                      </div>
                    </template>
                  </app-form-group>
                  <app-form-group
                    required
                    label-for="passport_issued_date"
                    :label="passportDataTextFillers.date_of_creation"
                  >
                    <app-input
                      v-model="form.passport_issued_date"
                      v-mask="'99.99.9999'"
                      id="passport_issued_date"
                      placeholder="дд.мм.гггг"
                      autocomplete="off"
                      :error="$v.form.passport_issued_date.$error || $v.passport_age.$invalid"
                      @paste.native.prevent
                      @input.native="onCheckPassportDate"
                      :readonly="!form.citizenship"
                    />
                    <template #error>
                      <div
                        v-if="$v.form.passport_issued_date.$dirty && !$v.form.passport_issued_date.underscorePresent"
                      >
                        Заполните поле полностью
                      </div>
                      <div v-if="$v.form.passport_issued_date.$dirty && !$v.form.passport_issued_date.required">
                        Обязательное поле
                      </div>
                      <div v-if="$v.form.passport_issued_date.$dirty && !$v.passport_age.between">
                        Некорректная дата
                      </div>
                    </template>
                  </app-form-group>
                  <app-form-group
                    v-if="form.citizenship === 2 || form.citizenship === 3"
                    label-for="passport_expiration_date"
                    label="Срок действия паспорта"
                  >
                    <app-input
                      v-model="form.passport_expiration_date"
                      v-mask="'99.99.9999'"
                      id="passport_expiration_date"
                      placeholder="дд.мм.гггг"
                      autocomplete="off"
                      :error="$v.form.passport_expiration_date.$error"
                      @paste.native.prevent
                    />
                    <template #error>
                      <div
                        v-if="
                          $v.form.passport_expiration_date.$dirty && !$v.form.passport_expiration_date.underscorePresent
                        "
                      >
                        Заполните поле полностью
                      </div>
                    </template>
                  </app-form-group>
                </template>
                <template #item-2>
                  <app-form-group
                    v-if="form.citizenship === 2 || form.citizenship === 3"
                    required
                    label="Страна гражданства"
                  >
                    <v-select
                      v-model="form.country"
                      :reduce="(item) => item.id"
                      :options="country_options"
                      :filterable="true"
                      :clearable="false"
                      :searchable="true"
                      label="name"
                      placeholder="Выберите значение"
                      class="select"
                      :class="{ 'select--error': $v.form.country.$error }"
                    >
                      <template #open-indicator>
                        <svg
                          width="18"
                          height="18"
                          fill="none"
                          class="open-indicator"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                          <path
                            d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                            fill="#fff"
                          ></path>
                        </svg>
                      </template>
                      <template #option="option">
                        <div class="select__item d-center">{{ option.name }}</div>
                      </template>
                      <template #selected-option="option">
                        <div class="selected d-center">{{ option.name }}</div>
                      </template>
                      <template #no-options>Ничего не найдено</template>
                    </v-select>
                    <template #error>
                      <div v-if="$v.form.country.$dirty && !$v.form.country.required">Обязательное поле</div>
                    </template>
                  </app-form-group>
                  <app-form-group required label-for="birthplace" :label="passportDataTextFillers.birthplace">
                    <app-input
                      v-model="form.birthplace"
                      id="birthplace"
                      :placeholder="passportDataTextFillers.birthplace_placeholder"
                      :error="$v.form.birthplace.$error"
                      :readonly="!form.citizenship"
                    />
                    <template #error>
                      <div v-if="$v.form.birthplace.$dirty && !$v.form.birthplace.required">Обязательное поле</div>
                    </template>
                  </app-form-group>
                  <app-form-group
                    :label="
                      form.citizenship === 2 || form.citizenship === 3
                        ? `Скан-копия документа, удостоверяющего личность иностранного гражданина (ID-карта или паспорт гражданина)`
                        : passportDataTextFillers.first_scan_copy
                    "
                    required
                  >
                    <app-uploader v-model="form.passport_scan_main" />
                    <template #error>
                      <div v-if="$v.form.passport_scan_main.$dirty && !$v.form.passport_scan_main.required">
                        Обязательное поле
                      </div>
                    </template>
                  </app-form-group>
                  <app-form-group
                    :label="
                      form.citizenship === 2 || form.citizenship === 3
                        ? `Скан-копия миграционной карты`
                        : passportDataTextFillers.second_scan_copy
                    "
                    required
                  >
                    <app-uploader v-model="form.passport_scan_additional" />
                    <template #error>
                      <div v-if="$v.form.passport_scan_additional.$dirty && !$v.form.passport_scan_additional.required">
                        Обязательное поле
                      </div>
                    </template>
                  </app-form-group>
                  <app-form-group
                    v-show="form.citizenship === 2 || form.citizenship === 3"
                    label="Скан-копия отрывной части уведомления о постановке на миграционный учет, оформленного принимающей стороной в субъекте проживания"
                    required
                  >
                    <app-uploader v-model="form.passport_scan_additional_2" />
                    <template #error>
                      <div
                        v-if="$v.form.passport_scan_additional_2.$dirty && !$v.form.passport_scan_additional_2.required"
                      >
                        Обязательное поле
                      </div>
                    </template>
                  </app-form-group>
                  <app-form-group
                    v-show="form.citizenship === 2 || form.citizenship === 3"
                    label="Cкан-копия полиса ДМС"
                    required
                  >
                    <app-uploader v-model="form.dms_scan" />
                    <template #error>
                      <div v-if="$v.form.dms_scan.$dirty && !$v.form.dms_scan.required">Обязательное поле</div>
                    </template>
                  </app-form-group>
                </template>
              </app-grid>
            </div>
          </template>
          <template #item-4>
            <div class="color-panel">
              <app-grid>
                <template #item-1>
                  <app-cells position="start">
                    <h2 class="title title--medium">Адреса</h2>
                  </app-cells>
                  <app-form-group
                    v-if="form.citizenship === 1 || form.citizenship === 2"
                    required
                    label="Адрес проживания"
                  >
                    <v-select
                      v-model="form.adress_residence_json"
                      :options="address_options"
                      :filterable="false"
                      :clearable="false"
                      @search="onAddressSearch"
                      label="address"
                      placeholder="Введите значение"
                      class="select"
                      :class="{ 'select--error': $v.form.adress_residence_json.$error }"
                    >
                      <template #open-indicator>
                        <svg
                          class="select__open-indicator"
                          xmlns="http://www.w3.org/2000/svg"
                          width="23"
                          height="23"
                          fill="none"
                        >
                          <path
                            clip-rule="evenodd"
                            d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                            stroke="#C7CFDD"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M20.12 20.12l-4.98-4.98"
                            stroke="#C7CFDD"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </template>
                      <template #no-options="{ search, searching }">
                        <div v-if="searching" class="select__noty">
                          По вашему запросу <em>{{ search }}</em> ничего не найдено.
                          <br />
                          Попробуйте еще раз.
                        </div>
                        <div v-else class="select__noty">
                          Для поиска адреса в справочнике, введите адрес в формате:
                          <span>Ставрополь, ул. Пушкина, д 65в, кв. 1</span>
                          и выберите значение из выпадающего списка.
                        </div>
                      </template>
                      <template #option="option">
                        <div class="select__item d-center">{{ option.address }}</div>
                      </template>
                      <template #selected-option="option">
                        <div class="selected d-center">{{ option.address }}</div>
                      </template>
                    </v-select>
                    <div
                      v-if="form.adress_residence_json && form.adress_residence_json.address.length > 30"
                      class="select__long"
                    >
                      {{ form.adress_residence_json && form.adress_residence_json.address }}
                    </div>
                    <template #error>
                      <div v-if="$v.form.adress_residence_json.$dirty && !$v.form.adress_residence_json.required">
                        Обязательное поле
                      </div>
                    </template>
                  </app-form-group>
                  <app-form-group v-else required label-for="adress_residence" label="Адрес проживания">
                    <app-input
                      v-model="form.adress_residence"
                      :error="$v.form.adress_residence.$error"
                      id="adress_residence"
                      placeholder="Введите значение"
                      :readonly="!form.citizenship"
                    />
                    <template #error>
                      <div v-if="$v.form.adress_residence.$dirty && !$v.form.adress_residence.required">
                        Обязательное поле
                      </div>
                    </template>
                  </app-form-group>
                  <app-form-group v-if="form.citizenship === 1" required label="Адрес регистрации">
                    <v-select
                      v-model="form.adress_registration_json"
                      :options="address_options"
                      :filterable="false"
                      :clearable="false"
                      @search="onAddressSearch"
                      label="address"
                      placeholder="Введите значение"
                      class="select"
                      :class="{ 'select--error': $v.form.adress_registration_json.$error }"
                    >
                      <template #open-indicator>
                        <svg
                          class="select__open-indicator"
                          xmlns="http://www.w3.org/2000/svg"
                          width="23"
                          height="23"
                          fill="none"
                        >
                          <path
                            clip-rule="evenodd"
                            d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                            stroke="#C7CFDD"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M20.12 20.12l-4.98-4.98"
                            stroke="#C7CFDD"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </template>
                      <template #no-options="{ search, searching }">
                        <div v-if="searching" class="select__noty">
                          По вашему запросу <em>{{ search }}</em> ничего не найдено.
                          <br />
                          Попробуйте еще раз.
                        </div>
                        <div v-else class="select__noty">
                          Для поиска адреса в справочнике, введите адрес в формате:
                          <span>Ставрополь, ул. Пушкина, д 65в, кв. 1</span>
                          и выберите значение из выпадающего списка.
                        </div>
                      </template>
                      <template #option="option">
                        <div class="select__item d-center">{{ option.address }}</div>
                      </template>
                      <template #selected-option="option">
                        <div class="selected d-center">{{ option.address }}</div>
                      </template>
                    </v-select>
                    <div
                      v-if="form.adress_registration_json && form.adress_registration_json.address.length > 30"
                      class="select__long"
                    >
                      {{ form.adress_registration_json && form.adress_registration_json.address }}
                    </div>
                    <template #error>
                      <div v-if="$v.form.adress_registration_json.$dirty && !$v.form.adress_registration_json.required">
                        Обязательное поле
                      </div>
                    </template>
                  </app-form-group>
                  <app-form-group v-else required label="Адрес регистрации" label-for="adress_registration">
                    <app-input
                      v-model="form.adress_registration"
                      :error="$v.form.adress_registration.$error"
                      id="adress_registration"
                      placeholder="Введите значение"
                      :readonly="!form.citizenship"
                    />
                    <template #error>
                      <div v-if="$v.form.adress_registration.$dirty && !$v.form.adress_registration.required">
                        Обязательное поле
                      </div>
                    </template>
                  </app-form-group>
                </template>
                <template #item-2>
                  <app-cells position="start">
                    <h2 class="title title--medium">Занятость</h2>
                  </app-cells>
                  <app-form-group required label="Тип занятости">
                    <app-cells position="start" :indent="false">
                      <app-radio
                        v-for="occ in occupation_options"
                        :key="occ.id"
                        v-model.number="form.occupation"
                        :label="occ.name"
                        :value="occ.id"
                        name="occupation"
                      />
                    </app-cells>
                    <template #error>
                      <div v-if="$v.form.occupation.$dirty && !$v.form.occupation.required">Обязательное поле</div>
                    </template>
                  </app-form-group>
                  <app-form-group v-if="form.occupation === 1" label="Название образовательной организации" required>
                    <v-select
                      v-model="form.organization_json"
                      :options="edu_options"
                      :filterable="false"
                      :clearable="false"
                      @search="onEDUSearch"
                      label="name"
                      placeholder="Введите значение"
                      class="select"
                      :class="{ 'select--error': $v.form.organization_json.$error }"
                    >
                      <template #open-indicator>
                        <svg
                          class="select__open-indicator"
                          xmlns="http://www.w3.org/2000/svg"
                          width="23"
                          height="23"
                          fill="none"
                        >
                          <path
                            clip-rule="evenodd"
                            d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                            stroke="#C7CFDD"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M20.12 20.12l-4.98-4.98"
                            stroke="#C7CFDD"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </template>
                      <template #no-options="{ search, searching }">
                        <div v-if="searching" class="select__noty">
                          По вашему запросу <em>{{ search }}</em> ничего не найдено.
                          <br />
                          Попробуйте еще раз.
                        </div>
                        <div v-else class="select__noty">
                          Для поиска образовательной организации в справочнике, введите наименование в формате:
                          <span>МБОУ СОШ 1 Ставрополь</span> или <span>Ставропольский ГАУ</span> или ИНН организации и
                          выберите значение из выпадающего списка.
                        </div>
                      </template>
                      <template #option="option">
                        <div class="select__item d-center">{{ option.name }} ({{ option.address }})</div>
                      </template>
                      <template #selected-option="option">
                        <div class="selected d-center">{{ option.name }} ({{ option.address }})</div>
                      </template>
                    </v-select>
                    <div v-if="form.organization_json && form.organization_json.name.length > 20" class="select__long">
                      {{ form.organization_json && form.organization_json.name }} ({{ form.organization_json.address }})
                    </div>
                    <template #error>
                      <div v-if="$v.form.organization_json.$dirty && !$v.form.organization_json.required">
                        Обязательное поле
                      </div>
                    </template>
                  </app-form-group>
                  <app-form-group v-if="form.occupation === 2" label="Место работы" required>
                    <v-select
                      v-model="form.organization_json"
                      :options="org_options"
                      :filterable="false"
                      :clearable="false"
                      @search="onOrgSearch"
                      label="name"
                      placeholder="Введите значение"
                      class="select"
                      :class="{ 'select--error': $v.form.organization_json.$error }"
                    >
                      <template #open-indicator>
                        <svg
                          class="select__open-indicator"
                          xmlns="http://www.w3.org/2000/svg"
                          width="23"
                          height="23"
                          fill="none"
                        >
                          <path
                            clip-rule="evenodd"
                            d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                            stroke="#C7CFDD"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M20.12 20.12l-4.98-4.98"
                            stroke="#C7CFDD"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </template>
                      <template #no-options="{ search, searching }">
                        <div v-if="searching" class="select__noty">
                          По вашему запросу <em>{{ search }}</em> ничего не найдено.
                          <br />
                          Попробуйте еще раз.
                        </div>
                        <div v-else class="select__noty">
                          Для поиска организации в справочнике, введите наименование в формате:
                          <span>Администрация ленинского района города Ставрополя</span> или ИНН
                          <span>2634055412</span> организации и выберите значение из выпадающего списка.
                        </div>
                      </template>
                      <template #option="option">
                        <div class="select__item d-center">{{ option.name }} ({{ option.address }})</div>
                      </template>
                      <template #selected-option="option">
                        <div class="selected d-center">{{ option.name }} ({{ option.address }})</div>
                      </template>
                    </v-select>
                    <div v-if="form.organization_json && form.organization_json.name.length > 20" class="select__long">
                      {{ form.organization_json && form.organization_json.name }} ({{ form.organization_json.address }})
                    </div>
                    <template #error>
                      <div v-if="$v.form.organization_json.$dirty && !$v.form.organization_json.required">
                        Обязательное поле
                      </div>
                    </template>
                  </app-form-group>
                  <app-form-group v-if="form.occupation === 1" label="Справка с места учебы" required>
                    <app-uploader v-model="form.certificate_scan" />
                    <template #error>
                      <div v-if="$v.form.certificate_scan.$dirty && !$v.form.certificate_scan.required">
                        Обязательное поле
                      </div>
                    </template>
                  </app-form-group>
                  <app-form-group v-else-if="form.occupation === 2" label="Должность" label-for="position" required>
                    <app-input
                      v-model="form.position"
                      id="position"
                      placeholder="Укажите занимаемую должность"
                      :error="$v.form.position.$error"
                    />
                    <template #error>
                      <div v-if="$v.form.position.$dirty && !$v.form.position.required">Обязательное поле</div>
                    </template>
                  </app-form-group>
                  <app-form-group
                    v-if="form.occupation === 3"
                    label="Укажите тип занятости, в случае отсутствия поставьте -"
                    label-for="position"
                    required
                  >
                    <app-input
                      v-model="form.position"
                      id="position"
                      placeholder="Введите значение"
                      :error="$v.form.position.$error"
                    />
                    <template #error>
                      <div v-if="$v.form.position.$dirty && !$v.form.position.required">Обязательное поле</div>
                    </template>
                  </app-form-group>
                </template>
              </app-grid>
            </div>
          </template>
          <template #item-5>
            <div class="color-panel">
              <app-cells position="start">
                <h2 class="title title--medium">Дополнительные сведения</h2>
              </app-cells>
              <app-grid>
                <template #item-1>
                  <app-form-group v-if="form.citizenship === 1" label-for="inn" label="ИНН">
                    <app-input
                      id="inn"
                      v-model="form.inn"
                      v-mask="'999-999-999-999'"
                      placeholder="111-111-111-111"
                      autocomplete="off"
                      class="input"
                      :error="$v.form.inn.$error"
                      @paste.native.prevent
                    />
                    <template #error>
                      <div v-if="$v.form.inn.$dirty && !$v.form.inn.underscorePresent">Заполните поле полностью</div>
                    </template>
                  </app-form-group>
                  <app-form-group v-if="form.citizenship === 1" label-for="snils" label="СНИЛС">
                    <app-input
                      id="snils"
                      v-model="form.snils"
                      v-mask="'999-999-999-99'"
                      placeholder="111-111-111-11"
                      autocomplete="off"
                      class="input"
                      :error="$v.form.snils.$error"
                      @paste.native.prevent
                    />
                    <template #error>
                      <div v-if="$v.form.snils.$dirty && !$v.form.snils.underscorePresent">
                        Заполните поле полностью
                      </div>
                    </template>
                  </app-form-group>
                  <app-form-group required label="Тип питания">
                    <v-select
                      v-model="form.food_type"
                      :reduce="(item) => item.id"
                      :options="meal_options"
                      :filterable="false"
                      :clearable="false"
                      :searchable="false"
                      label="name"
                      placeholder="Выберите значение"
                      class="select"
                      :class="{ 'select--error': $v.form.food_type.$error }"
                    >
                      <template #open-indicator>
                        <svg
                          width="18"
                          height="18"
                          fill="none"
                          class="open-indicator"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                          <path
                            d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                            fill="#fff"
                          ></path>
                        </svg>
                      </template>
                      <template #option="option">
                        <div class="select__item d-center">{{ option.name }}</div>
                      </template>
                      <template #selected-option="option">
                        <div class="selected d-center">{{ option.name }}</div>
                      </template>
                      <template #no-options>Ничего не найдено</template>
                    </v-select>
                    <template #error>
                      <div v-if="$v.form.food_type.$dirty && !$v.form.food_type.required">Обязательное поле</div>
                    </template>
                  </app-form-group>
                  <app-form-group required label="Размер одежды">
                    <v-select
                      v-model="form.clothingsize"
                      :reduce="(item) => item.id"
                      :options="clothingsize_options"
                      append-to-body
                      :calculate-position="withPopper"
                      :filterable="false"
                      :clearable="false"
                      :searchable="false"
                      label="name"
                      placeholder="Выберите значение"
                      class="select"
                      :class="{ 'select--error': $v.form.clothingsize.$error }"
                    >
                      <template #open-indicator>
                        <svg
                          width="18"
                          height="18"
                          fill="none"
                          class="open-indicator"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                          <path
                            d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                            fill="#fff"
                          ></path>
                        </svg>
                      </template>
                      <template #option="option">
                        <div class="select__item d-center">{{ option.name }}</div>
                      </template>
                      <template #selected-option="option">
                        <div class="selected d-center">{{ option.name }}</div>
                      </template>
                      <template #no-options>Ничего не найдено</template>
                    </v-select>
                    <template #error>
                      <div v-if="$v.form.clothingsize.$dirty && !$v.form.clothingsize.required">Обязательное поле</div>
                    </template>
                  </app-form-group>
                </template>
                <template #item-2>
                  <app-form-group required label="Являетесь ли вы членом РСМ">
                    <app-cells position="start" :indent="false">
                      <label class="radio">
                        <span class="radio__text">Да</span>
                        <input v-model="form.is_member_rsm" type="radio" :value="true" />
                        <span class="radio__radiomark"></span>
                      </label>
                      <label class="radio">
                        <span class="radio__text">Нет</span>
                        <input v-model="form.is_member_rsm" type="radio" :value="false" />
                        <span class="radio__radiomark"></span>
                      </label>
                    </app-cells>
                    <template #error>
                      <div v-if="$v.form.is_member_rsm.$dirty && !$v.form.is_member_rsm.required">
                        Обязательное поле
                      </div>
                    </template>
                  </app-form-group>
                  <app-form-group
                    v-if="form.is_member_rsm"
                    label="Укажите номер членского билета РСМ"
                    label-for="ticket_number_rsm"
                    required
                  >
                    <app-input
                      v-model.number="form.ticket_number_rsm"
                      type="number"
                      id="ticket_number_rsm"
                      placeholder="Введите значение"
                      :error="$v.form.ticket_number_rsm.$error"
                    />
                    <template #error>
                      <div v-if="$v.form.ticket_number_rsm.$dirty && !$v.form.ticket_number_rsm.required">
                        Обязательное поле
                      </div>
                    </template>
                  </app-form-group>
                  <app-form-group v-show="form.is_member_rsm" label="Скан-копия членского билета РСМ">
                    <app-uploader v-model="form.ticket_scan_rsm" />
                  </app-form-group>
                  <app-form-group v-if="form.is_member_rsm === false" label="Хотели бы вы стать членом РСМ" required>
                    <app-cells position="start" :indent="false">
                      <label class="radio">
                        <span class="radio__text">Да</span>
                        <input v-model="form.would_join_rsm" type="radio" :value="true" />
                        <span class="radio__radiomark"></span>
                      </label>
                      <label class="radio">
                        <span class="radio__text">Нет</span>
                        <input v-model="form.would_join_rsm" type="radio" :value="false" />
                        <span class="radio__radiomark"></span>
                      </label>
                    </app-cells>
                    <template #error>
                      <div v-if="$v.form.would_join_rsm.$dirty && !$v.form.would_join_rsm.required">
                        Обязательное поле
                      </div>
                    </template>
                  </app-form-group>

                  <app-form-group required label="Есть регистрация в проекте на сайте будьвдвижении.рф">
                    <app-cells position="start" :indent="false">
                      <label class="radio">
                        <span class="radio__text">Да</span>
                        <input v-model="form.is_member_dp" type="radio" :value="true" />
                        <span class="radio__radiomark"></span>
                      </label>
                      <label class="radio">
                        <span class="radio__text">Нет</span>
                        <input v-model="form.is_member_dp" type="radio" :value="false" />
                        <span class="radio__radiomark"></span>
                      </label>
                    </app-cells>
                    <template #error>
                      <div v-if="$v.form.is_member_dp.$dirty && !$v.form.is_member_dp.required">Обязательное поле</div>
                    </template>
                  </app-form-group>
                  <app-form-group
                    v-if="form.is_member_dp"
                    label="Укажите номер ID на сайте будьвдвижении.рф"
                    label-for="id_number_dp"
                    required
                  >
                    <app-input
                      v-model.number="form.id_number_dp"
                      type="number"
                      id="id_number_dp"
                      placeholder="Введите значение"
                      :error="$v.form.id_number_dp.$error"
                    />
                    <template #error>
                      <div v-if="$v.form.id_number_dp.$dirty && !$v.form.id_number_dp.required">Обязательное поле</div>
                    </template>
                  </app-form-group>

                </template>
              </app-grid>
            </div>
          </template>
        </app-grid>

        <app-cells>
          <app-button ref="submit" :disabled="$v.form.$error || form.phone === 0">Отправить анкету</app-button>
        </app-cells>
      </form>
    </div>
    <div v-if="!hash_valid && !hash_validation_request" class="public-body__block public-body__block--center">
      <img src="@/assets/img/public/error.svg" alt="logo" />
      <p class="public-body__text">
        Делегация не найдена или прием заявок был завершен.<br />Обратитесь к руководителю делегации.
      </p>
    </div>
    <div v-if="submit_success" class="public-body__block public-body__block--center">
      <img src="@/assets/img/public/success-icon.svg" alt="logo" />
      <p class="public-body__text">Ваша заявка успешно отправлена.</p>
    </div>
  </div>
</template>

<script>
import {
  getCitizenship,
  getParticipationStatus,
  getMealType,
  getFMS,
  getGender,
  getLegalRepresentative,
  getClothingSize,
  getOccupation,
  getEduOrg,
  getOrg,
  getCountry,
  getAddress,
  postMemberFromHash,
  checkDelegationHash,
  getFile,
} from '@/http'
import { dateFormatting } from '@/helpers'
import { minLength, maxLength, required, email, between, requiredIf } from 'vuelidate/lib/validators'
import debounce from 'lodash/debounce'
import { differenceInYears, parse } from 'date-fns'
import { createPopper } from '@popperjs/core'
import IconTooltip from '@/components/icons/IconTooltip'

const festival_min_date_parsed = parse(process.env.VUE_APP_FESTIVAL_MIN_DATE, 'dd.MM.yyyy', new Date())
const festival_max_date_parsed = parse(process.env.VUE_APP_FESTIVAL_MAX_DATE, 'dd.MM.yyyy', new Date())

const underscorePresent = (value) => {
  if (!value) return true
  else return !value.includes('_')
}

export default {
  name: 'MembersHashCreate',
  components: {
    IconTooltip,
  },
  data() {
    return {
      form: {
        crop: {},
        adress_residence_json: '',
        adress_residence: '',
        adress_registration_json: '',
        adress_registration: '',
        organization_json: '',
        legal_representative_type: 1,
        passport_issued_by: '',
        ticket_number_rsm: '',
        id_number_dp: '',
        position: '',
      },
      app_name: process.env.VUE_APP_FESTIVAL_NAME,
      citizenship_options: [],
      participation_options: [],
      meal_options: [],
      fms_options: [],
      gender_options: [],
      passport_options: [
        { id: 'passport', label: 'Паспорт' },
        {
          id: 'birthday_certificate',
          label: 'Свидетельство о рождении',
        },
      ],
      passport_option: 'passport',
      representative_options: [],
      clothingsize_options: [],
      occupation_options: [],
      edu_options: [],
      org_options: [],
      address_options: [],
      member_min_year: null,
      member_max_year: null,
      birthday_age: 25,
      passport_age: 25,
      placement: 'bottom',
      hash_valid: false,
      submit_success: false,
      hash_validation_request: true,
      delegation_hash: '',
      delegation_name: '',
      country_options: [],
      documents_url: process.env.VUE_APP_STATIC_DOCUMENTS_URL,
    }
  },
  validations() {
    const form = {
      crop: { required },
      citizenship: { required },
      birthday: { required, underscorePresent },
      email: { required, email },
      participation_status: { required },
      first_name: { required },
      last_name: { required },
      phone: { required },
      vk_link: { required },
      food_type: { required },
      clothingsize: { required },
      is_member_rsm: { required },
      is_member_dp: { required },
      passport_issued_date: { required, underscorePresent },
      passport_expiration_date: { underscorePresent },
      inn: { underscorePresent },
      snils: { underscorePresent },
      birthplace: { required },
      passport_scan_main: { required },
      passport_scan_additional: { required },
      passport_scan_additional_2: {},
      dms_scan: {},
      passport_issued_by: { required },
      occupation: { required },
      gender: { required },
      organization_json: { required },
      passport_series: {
        required: requiredIf(() => this.form.citizenship === 1),
        minLength: minLength(this.seriesMinlength),
        maxLength: maxLength(this.seriesMaxlength),
      },
      passport_number: {
        required: requiredIf(() => this.form.citizenship === 1),
        minLength: minLength(this.numberMinlength),
        maxLength: maxLength(this.numberMaxlength),
      },
      adress_residence_json: {},
      adress_registration_json: {},
      adress_residence: {},
      adress_registration: {},
      legal_representative_type: {},
      legal_representative_passport: {},
      legal_representative_confirmation: {},
      legal_representative_medical: {},
      legal_representative_agree: {},
      legal_representative_pdata: {},
      legal_representative_additional_agree: {},
      ticket_number_rsm: {},
      would_join_rsm: {},
      certificate_scan: {},
      id_number_dp: {},
      position: {},
      country: {},
    }

    if (this.form.citizenship === 1) {
      form.adress_residence_json = { required }
      form.adress_registration_json = { required }
      form.country = {}
    }

    if (this.form.citizenship === 2) {
      form.adress_residence_json = { required }
      form.adress_registration_json = {}
      form.adress_residence = {}
      form.adress_registration = { required }
      form.country = { required }
      form.passport_scan_additional_2 = { required }
      form.dms_scan = { required }
    }

    if (this.form.citizenship === 3) {
      form.adress_residence_json = {}
      form.adress_registration_json = {}
      form.adress_residence = { required }
      form.adress_registration = { required }
      form.country = { required }
      form.passport_scan_additional_2 = { required }
      form.dms_scan = { required }
    }

    if (this.member_min_year && this.member_min_year < 18) {
      form.legal_representative_type = { required }
      form.legal_representative_passport = { required }
      form.legal_representative_confirmation = { required }
      form.legal_representative_medical = { required }
      form.legal_representative_agree = { required }
      form.legal_representative_pdata = { required }
      form.legal_representative_additional_agree = { required }
    }

    if (this.form.is_member_rsm) {
      form.ticket_number_rsm = { required }
      form.would_join_rsm = {}
    } else {
      form.ticket_number_rsm = {}
      form.would_join_rsm = { required }
    }

    if (this.form.is_member_dp) {
      form.id_number_dp = { required }
    } else {
      form.id_number_dp = {}
    }

    if (this.form.occupation === 1) {
      form.certificate_scan = { required }
      form.position = {}
    } else {
      form.certificate_scan = {}
      form.position = { required }
    }

    if (this.form.occupation === 3) {
      form.organization_json = {}
    }

    return {
      form,
      birthday_age: { between: between(1, 90) },
      passport_age: { between: between(1, 90) },
    }
  },
  computed: {
    seriesMinlength() {
      if (this.form.citizenship === 1) return 4
      return 0
    },
    seriesMaxlength() {
      if (this.form.citizenship === 1) return 4
      return 100
    },
    numberMinlength() {
      if (this.form.citizenship === 1) return 6
      return 0
    },
    numberMaxlength() {
      if (this.form.citizenship === 1) return 6
      return 100
    },
    legalRepresentationLabel() {
      if (this.form.legal_representative_type === 1) {
        return this.app_name === 'poo'
          ? 'Скан-копия паспорта родителя страница "Дети" или Свидетельство о рождении'
          : 'Скан-копия паспорта родителя (Страница с фото и страница Дети (если там указано ФИО ребенка) или Свидетельство о рождении)'
      }
      return 'Скан-копия документа, подтверждающего статус представителя'
    },
    maxAge() {
      if (this.isParticipant()) return 18
      return 90
    },
    minAge() {
      if (this.isParticipant()) return 12
      return 18
    },
    isRussianParticipantWithAge() {
      return !!this.member_min_year && this.form.citizenship === 1 && this.isParticipant()
    },
    isParticipantWithoutPassport() {
      return (
        this.isRussianParticipantWithAge &&
        (this.member_min_year < 14 ||
          (this.member_min_year === 14 && this.passport_option === 'birthday_certificate'))
      )
    },
    passportDataTextFillers() {
      if (this.isParticipantWithoutPassport) {
        return {
          title: 'Свидетельство о рождении',
          series: 'Серия свидетельства',
          series_placeholder: 'Введите серию свидетельства',
          number: 'Номер свидетельства',
          number_placeholder: 'Введите номер свидетельства',
          who_created: 'Кем выдано свидетельство ',
          date_of_creation: 'Дата выдачи свидетельства',
          birthplace: 'Место рождения',
          birthplace_placeholder: 'Как в свидетельстве о рождении ',
          first_scan_copy: 'Скан-копия свидетельства о рождении ',
          second_scan_copy: 'Скан-копия свидетельство о регистрации по месту жительства ',
        }
      }
      return {
        title: 'Паспортные данные',
        series: 'Серия паспорта',
        series_placeholder: 'Введите серию паспорта',
        number: 'Номер паспорта',
        number_placeholder: 'Введите номер паспорта',
        who_created: 'Кем выдан паспорт',
        date_of_creation: 'Дата выдачи паспорта',
        birthplace: 'Место рождения',
        birthplace_placeholder: 'Как в паспорте',
        first_scan_copy: 'Скан-копия паспорта (основной разворот)',
        second_scan_copy: 'Скан-копия паспорта (разворот с регистрацией)',
      }
    },
  },
  watch: {
    'form.is_member_rsm'(val) {
      if (!val) this.form.ticket_number_rsm = null
    },
    'form.is_member_dp'(val) {
      if (!val) this.form.id_number_dp = null
    },
    'form.occupation'(val, oldVal) {
      if (oldVal) {
        this.form.organization_json = ''
        if (val !== 1) {
          this.form.certificate_scan = {}
        }
        this.form.position = ''
      }
    },
  },
  created() {
    this.delegation_hash = this.$route.params.id
    checkDelegationHash(this.delegation_hash)
      .then((response) => {
        this.delegation_name = response.data.name
        this.hash_valid = true
        getCitizenship().then((response) => {
          this.citizenship_options = response.data
        })
        getParticipationStatus().then((response) => {
          this.participation_options = response.data
        })
        getMealType().then((response) => {
          this.meal_options = response.data
        })
        getGender().then((response) => {
          this.gender_options = response.data
        })
        getLegalRepresentative().then((response) => {
          this.representative_options = response.data
        })
        getClothingSize().then((response) => {
          this.clothingsize_options = response.data
        })
        getOccupation().then((response) => {
          this.occupation_options = response.data
        })
        getCountry().then((response) => {
          this.country_options = response.data
        })
      })
      .catch(() => {
        this.hash_valid = false
      })
      .finally(() => {
        this.hash_validation_request = false
      })
  },
  methods: {
    isParticipant() {
      // Участник или Участник (резерв)
      return this.form.participation_status === 1 || this.form.participation_status === 6
    },
    onDownloadFile(url, name) {
      getFile(url).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${name}.docx`)
        document.body.appendChild(link)
        link.click()
        URL.revokeObjectURL(link.href)
      })
    },
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.',
        })
      } else {
        if (this.form.crop.coords && !this.form.crop.photo) {
          this.$refs.cropper.sendCrop().then(() => {
            this.form.photo_id = this.form.crop.photo
            this.form.coords = this.form.crop.coords
            this.sendForm()
          })
        } else {
          this.form.coords = this.form.crop.coords
          this.sendForm()
        }
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      postMemberFromHash(this.normalizeForm())
        .finally(() => {
          this.$refs.submit.preload = false
        })
        .then(() => {
          this.submit_success = true
        })
    },
    normalizeForm() {
      const form = { ...this.form }
      form.delegation_hash = this.delegation_hash
      form.passport_scan_main = form.passport_scan_main.id
      form.passport_scan_additional = form.passport_scan_additional.id
      if (typeof form.passport_scan_additional_2 === 'object') form.passport_scan_additional_2 = form.passport_scan_additional_2.id
      if (typeof form.dms_scan === 'object') form.dms_scan = form.dms_scan.id

      if (this.member_min_year < 18) {
        form.legal_representative_passport = form.legal_representative_passport.id
        form.legal_representative_confirmation = form.legal_representative_confirmation.id
        form.legal_representative_medical = form.legal_representative_medical.id
        form.legal_representative_agree = form.legal_representative_agree.id
        form.legal_representative_pdata = form.legal_representative_pdata.id
        form.legal_representative_additional_agree = form.legal_representative_additional_agree.id
      } else {
        delete form.legal_representative_passport
        delete form.legal_representative_confirmation
        delete form.legal_representative_medical
        delete form.legal_representative_agree
        delete form.legal_representative_pdata
        delete form.legal_representative_additional_agree
        delete form.legal_representative_type
      }
      if (!form.is_member_rsm) {
        delete form.ticket_scan_rsm
        delete form.ticket_number_rsm
      } else {
        delete form.would_join_rsm
        if (form.ticket_scan_rsm) form.ticket_scan_rsm = form.ticket_scan_rsm.id
      }
      if (!form.is_member_dp) {
        delete form.id_number_dp
      }
      if (form.citizenship === 1) delete form.country
      if (form.occupation === 1) {
        form.certificate_scan = form.certificate_scan.id
      } else {
        delete form.certificate_scan
      }
      if (form.adress_residence_json) form.adress_residence = form.adress_residence_json.address
      if (form.adress_registration_json) form.adress_registration = form.adress_registration_json.address
      if (typeof form.passport_issued_by === 'object') {
        form.passport_unit_code = form.passport_issued_by.code
        form.passport_issued_by = form.passport_issued_by.name
      }
      if (form.organization_json && form.organization_json.name) form.organization = form.organization_json.name
      else delete form.organization
      form.birthday = dateFormatting(form.birthday, 'normal-to-iso-small')
      return form
    },
    onChangeCitizenship() {
      this.form.passport_issued_by = ''
      this.form.adress_residence_json = ''
      this.form.adress_residence = ''
      this.form.adress_registration_json = ''
      this.form.adress_registration = ''
    },
    onCheckBirthdayDate() {
      this.$v.form.birthday.$touch()
      const birth_date = parse(this.form.birthday, 'dd.MM.yyyy', new Date())
      // на текущую дату
      this.birthday_age = differenceInYears(new Date(), birth_date)
      if (!this.$v.form.birthday.$error) {
        // на начало мероприятия == минимальный возраст во время
        this.member_min_year = differenceInYears(festival_min_date_parsed, birth_date)
        // на конец мероприятия == максимальный возраст во время
        this.member_max_year = differenceInYears(festival_max_date_parsed, birth_date)
      }
    },
    onCheckPassportDate() {
      this.$v.form.passport_issued_date.$touch()
      // возраст по паспорту на текущий момент
      this.passport_age = differenceInYears(new Date(), parse(this.form.passport_issued_date, 'dd.MM.yyyy', new Date()))
    },
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 0],
              adaptive: true,
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle('drop-up', state.placement === 'top')
            },
          },
        ],
      })
      return () => popper.destroy()
    },
    onFMSSearch(search, loading) {
      if (!search || search.length < 3) return
      loading(true)
      this.searchFMS(loading, search, this)
    },
    searchFMS: debounce((loading, search, vm) => {
      getFMS(search).then((response) => {
        vm.fms_options = response.data
        loading(false)
      })
    }, 1000),
    onEDUSearch(search, loading) {
      if (!search || search.length < 3) return
      loading(true)
      this.searchEDU(loading, search, this)
    },
    searchEDU: debounce((loading, search, vm) => {
      getEduOrg(search).then((response) => {
        vm.edu_options = response.data
        loading(false)
      })
    }, 1000),
    onOrgSearch(search, loading) {
      if (!search || search.length < 3) return
      loading(true)
      this.searchOrg(loading, search, this)
    },
    searchOrg: debounce((loading, search, vm) => {
      getOrg(search).then((response) => {
        vm.org_options = response.data
        loading(false)
      })
    }, 1000),
    onAddressSearch(search, loading) {
      if (!search || search.length < 3) return
      loading(true)
      this.searchAddress(loading, search, this)
    },
    searchAddress: debounce((loading, search, vm) => {
      getAddress(search).then((response) => {
        vm.address_options = response.data
        loading(false)
      })
    }, 1000),
  },
}
</script>
