<template>
  <div class="public-body">
    <div class="public-body__block public-body__block--decor">
      <a href="/">
        <img src="@/assets/img/login-logo.svg" alt="logo" />
      </a>
    </div>
    <div v-if="hash_valid && !submit_success" class="public-body__block">
      <h1 class="title title--theme title--indent">Добавление СМИ</h1>
      <app-cells position="start">
        <div class="require-text">
          <sup>*</sup> - поля обязательные для заполнения
        </div>
      </app-cells>
      <form @submit.prevent="onCheckForm">
        <app-form-group required label="Гражданство">
          <v-select
            v-model.number="form.citizenship"
            :reduce="item => item.id"
            :options="citizenship_options"
            @input="onChangeCitizenship"
            :filterable="false"
            :clearable="false"
            :searchable="false"
            label="name"
            placeholder="Выберите значение"
            class="select"
            :class="{ 'select--error': $v.form.citizenship.$error }"
          >
            <template #open-indicator>
              <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
              </svg>
            </template>
            <template #option="option">
              <div class="select__item d-center">{{ option.name }}</div>
            </template>
            <template #selected-option="option">
              <div class="selected d-center">{{ option.name }}</div>
            </template>
            <template #no-options>Ничего не найдено</template>
          </v-select>
          <template #error>
            <div v-if="$v.form.citizenship.$dirty && !$v.form.citizenship.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group v-if="form.citizenship === 2 || form.citizenship === 3" required label="Страна гражданства">
          <v-select
            v-model="form.country"
            :reduce="item => item.id"
            :options="country_options"
            :filterable="true"
            :clearable="false"
            :searchable="true"
            label="name"
            placeholder="Выберите значение"
            class="select"
            :class="{ 'select--error': $v.form.country.$error }"
          >
            <template #open-indicator>
              <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
              </svg>
            </template>
            <template #option="option">
              <div class="select__item d-center">{{ option.name }}</div>
            </template>
            <template #selected-option="option">
              <div class="selected d-center">{{ option.name }}</div>
            </template>
            <template #no-options>Ничего не найдено</template>
          </v-select>
          <template #error>
            <div v-if="$v.form.country.$dirty && !$v.form.country.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group required label="Фамилия" label-for="last_name">
          <app-input
            v-model="form.last_name"
            id="last_name"
            placeholder="Введите фамилию"
            :error="$v.form.last_name.$error"
            @change.native="$v.form.last_name.$touch()"
          />
          <template #error>
            <div v-if="$v.form.last_name.$dirty && !$v.form.last_name.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group required label="Имя" label-for="first_name">
          <app-input
            v-model="form.first_name"
            id="first_name"
            placeholder="Введите имя"
            :error="$v.form.first_name.$error"
            @change.native="$v.form.first_name.$touch()"
          />
          <template #error>
            <div v-if="$v.form.first_name.$dirty && !$v.form.first_name.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group label="Отчество" label-for="patronymic">
          <app-input
            v-model="form.patronymic"
            id="patronymic"
            placeholder="Введите отчество"
          />
        </app-form-group>
        <app-form-group>
          <app-cropper-web-cam
            v-model="form.crop"
            :aspectRatio="3/4"
            ref="cropper"
          />
        </app-form-group>
        <app-form-group required label="Пол">
          <app-cells position="start" :indent="false">
            <app-radio
              v-for="gender in gender_options"
              :key="gender.id"
              v-model.number="form.gender"
              :value="gender.id"
              :label="gender.name"
              name="gender"
            />
          </app-cells>
          <template #error>
            <div v-if="$v.form.gender.$dirty && !$v.form.gender.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group required label-for="birthday" label="Дата рождения">
          <app-input
            v-model="form.birthday"
            v-mask="'99.99.9999'"
            id="birthday"
            placeholder="дд.мм.гггг"
            autocomplete="off"
            :error="$v.form.birthday.$error || $v.birthday_age.$invalid"
            @paste.native.prevent
            @input.native="onCheckBirthdayDate"
          />
          <template #error>
            <div
              v-if="member_full_year && !$v.form.birthday.$error"
              class="form-group__age"
              :class="{
                          'green': member_full_year >= 18 && member_full_year <= 35,
                          'yellow': member_full_year > 35 || (member_full_year < 18 && member_full_year >= 16)
                        }"
            >
              <span v-if="member_full_year < 16">Недопустимый возраст участника:</span>
              <span v-else>Возраст на момент проведения фестиваля:</span>
              {{ member_full_year }}
            </div>
            <div v-if="$v.form.birthday.$dirty && !$v.form.birthday.underscorePresent">Заполните поле полностью</div>
            <div v-if="$v.form.birthday.$dirty && !$v.form.birthday.required">Обязательное поле</div>
            <div v-if="$v.form.birthday.$dirty && !$v.birthday_age.between">Некорректная дата</div>
          </template>
        </app-form-group>
        <app-form-group required label="Серия паспорта" label-for="passport_series">
          <app-input
            v-model="form.passport_series"
            id="passport_series"
            :type="form.citizenship === 1 ? 'number' : 'text'"
            placeholder="Введите серию паспорта"
            :error="$v.form.passport_series.$error"
            @change.native="$v.form.passport_series.$touch()"
            :readonly="!form.citizenship"
          />
          <template #error>
            <div v-if="$v.form.passport_series.$dirty && !$v.form.passport_series.required">Обязательное поле</div>
            <div v-if="$v.form.passport_series.$dirty && !$v.form.passport_series.minLength">Серия паспорта не может быть меньше 4 цифр</div>
            <div v-if="$v.form.passport_series.$dirty && !$v.form.passport_series.maxLength">Серия паспорта не может быть больше 4 цифр</div>
          </template>
        </app-form-group>
        <app-form-group required label="Номер паспорта" label-for="passport_number">
          <app-input
            v-model="form.passport_number"
            id="passport_number"
            :type="form.citizenship === 1 ? 'number' : 'text'"
            placeholder="Введите номер паспорта"
            :error="$v.form.passport_number.$error"
            @change.native="$v.form.passport_number.$touch()"
            :readonly="!form.citizenship"
          />
          <template #error>
            <div v-if="$v.form.passport_number.$dirty && !$v.form.passport_number.required">Обязательное поле</div>
            <div v-if="$v.form.passport_number.$dirty && !$v.form.passport_number.minLength">Номер паспорта не может быть меньше 6 цифр</div>
            <div v-if="$v.form.passport_number.$dirty && !$v.form.passport_number.maxLength">Номер паспорта не может быть больше 6 цифр</div>
          </template>
        </app-form-group>
        <app-form-group v-if="form.citizenship === 1" label="Кем выдан паспорт" required>
          <v-select
            v-model="form.passport_issued_by"
            :options="fms_options"
            :filterable="false"
            :clearable="false"
            @search="onFMSSearch"
            label="name"
            placeholder="Введите значение"
            class="select"
            :class="{ 'select--error': $v.form.passport_issued_by.$error }"
          >
            <template #open-indicator>
              <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                   fill="none">
                <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                      stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"></path>
              </svg>
            </template>
            <template #no-options="{ search, searching }">
              <div v-if="searching" class="select__noty">
                По вашему запросу <em>{{ search }}</em> ничего не найдено.
                <br>
                Попробуйте еще раз.
              </div>
              <div v-else class="select__noty">
                Для поиска подразделения выдавшего паспорт в справочнике, введите код подразделения в формате: <span>260-012</span> или полное наименование <span>ГУ МВД РОССИИ ПО СТАВРОПОЛЬСКОМУ КРАЮ</span> и выберите значение из выпадающего списка.
              </div>
            </template>
            <template #option="option">
              <div class="select__item d-center">({{ option.code}}) {{option.name}}</div>
            </template>
            <template #selected-option="option">
              <div class="selected d-center">({{ option.code}}) {{option.name}}</div>
            </template>
          </v-select>
          <div
            v-if="form.passport_issued_by && form.passport_issued_by.name.length > 20"
            class="select__long"
          >
            {{ form.passport_issued_by && form.passport_issued_by.name }}
          </div>
          <template #error>
            <div v-if="$v.form.passport_issued_by.$dirty && !$v.form.passport_issued_by.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group v-else-if="form.citizenship === 2 || form.citizenship === 3" label-for="passport_fms_name" label="Кем выдан паспорт" required>
          <app-input
            key="passport_issued_by"
            v-model="form.passport_issued_by"
            id="passport_fms_name"
            placeholder="Введите кем выдан паспорт"
            :error="$v.form.passport_issued_by.$error"
          />
          <template #error>
            <div v-if="$v.form.passport_issued_by.$dirty && !$v.form.passport_issued_by.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group required label-for="passport_issued_date" label="Дата выдачи паспорта">
          <app-input
            v-model="form.passport_issued_date"
            v-mask="'99.99.9999'"
            id="passport_issued_date"
            placeholder="дд.мм.гггг"
            autocomplete="off"
            :error="$v.form.passport_issued_date.$error || $v.passport_age.$invalid"
            @paste.native.prevent
            @input.native="onCheckPassportDate"
            :readonly="!form.citizenship"
          />
          <template #error>
            <div v-if="$v.form.passport_issued_date.$dirty && !$v.form.passport_issued_date.underscorePresent">Заполните поле полностью</div>
            <div v-if="$v.form.passport_issued_date.$dirty && !$v.form.passport_issued_date.required">Обязательное поле</div>
            <div v-if="$v.form.passport_issued_date.$dirty && !$v.passport_age.between">Некорректная дата</div>
          </template>
        </app-form-group>
        <app-form-group v-if="form.citizenship === 2 || form.citizenship === 3" label-for="passport_expiration_date" label="Срок действия паспорта">
          <app-input
            v-model="form.passport_expiration_date"
            v-mask="'99.99.9999'"
            id="passport_expiration_date"
            placeholder="дд.мм.гггг"
            autocomplete="off"
            :error="$v.form.passport_expiration_date.$error"
            @paste.native.prevent
          />
          <template #error>
            <div v-if="$v.form.passport_expiration_date.$dirty && !$v.form.passport_expiration_date.underscorePresent">Заполните поле полностью</div>
          </template>
        </app-form-group>
        <app-form-group v-if="form.citizenship === 1" required label="Адрес регистрации">
          <v-select
            v-model="form.adress_registration"
            :options="address_options"
            :filterable="false"
            :clearable="false"
            @search="onAddressSearch"
            label="address"
            placeholder="Введите значение"
            class="select"
            :class="{ 'select--error': $v.form.adress_registration.$error }"
          >
            <template #open-indicator>
              <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                   fill="none">
                <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                      stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"></path>
              </svg>
            </template>
            <template #no-options="{ search, searching }">
              <div v-if="searching" class="select__noty">
                По вашему запросу <em>{{ search }}</em> ничего не найдено.
                <br>
                Попробуйте еще раз.
              </div>
              <div v-else class="select__noty">
                Для поиска адреса в справочнике, введите адрес в формате: <span>Ставрополь, ул. Пушкина, д 65в, кв. 1</span> и выберите значение из выпадающего списка.
              </div>
            </template>
            <template #option="option">
              <div class="select__item d-center">{{option.address}}</div>
            </template>
            <template #selected-option="option">
              <div class="selected d-center">{{option.address}}</div>
            </template>
          </v-select>
          <div
            v-if="form.adress_registration && form.adress_registration.address.length > 30"
            class="select__long"
          >
            {{ form.adress_registration && form.adress_registration.address }}
          </div>
          <template #error>
            <div v-if="$v.form.adress_registration.$dirty && !$v.form.adress_registration.required">Обязательное поле</div>
          </template>
        </app-form-group >
        <app-form-group v-else required label="Адрес регистрации" label-for="adress_registration">
          <app-input
            v-model="form.adress_registration"
            :error="$v.form.adress_registration.$error"
            id="adress_registration"
            placeholder="Введите значение"
            :readonly="!form.citizenship"
          />
          <template #error>
            <div v-if="$v.form.adress_registration.$dirty && !$v.form.adress_registration.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group required label="Название СМИ" label-for="name">
          <app-input
            v-model="form.name"
            id="name"
            placeholder="Введите значение"
            :error="$v.form.name.$error"
          />
          <template #error>
            <div v-if="$v.form.name.$dirty && !$v.form.name.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group required label="Статус">
          <v-select
            v-model="form.smi_status"
            :reduce="item => item.id"
            :options="smi_types"
            :filterable="false"
            :clearable="false"
            :searchable="false"
            label="name"
            placeholder="Выберите значение"
            class="select"
            :class="{ 'select--error': $v.form.smi_status.$error }"
          >
            <template #open-indicator>
              <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
              </svg>
            </template>
            <template #option="option">
              <div class="select__item d-center">{{ option.name }}</div>
            </template>
            <template #selected-option="option">
              <div class="selected d-center">{{ option.name }}</div>
            </template>
            <template #no-options>Ничего не найдено</template>
          </v-select>
          <template #error>
            <div v-if="$v.form.smi_status.$dirty && !$v.form.smi_status.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group required label="Должность" label-for="position">
          <app-input
            v-model="form.position"
            id="position"
            placeholder="Введите значение"
            :error="$v.form.position.$error"
          />
          <template #error>
            <div v-if="$v.form.position.$dirty && !$v.form.position.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group required label="Полное наименование субъекта РФ, который представляет ваше СМИ">
          <v-select
            v-model="form.region"
            :reduce="item => item.id"
            :options="region_options"
            :filterable="true"
            :clearable="false"
            :searchable="true"
            label="name"
            placeholder="Выберите значение"
            class="select"
            :class="{ 'select--error': $v.form.region.$error }"
          >
            <template #open-indicator>
              <svg width="18" height="18" fill="none" class="open-indicator"
                   xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                <path
                  d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                  fill="#fff"></path>
              </svg>
            </template>
            <template #option="option">
              <div class="select__item d-center">{{ option.name }}</div>
            </template>
            <template #selected-option="option">
              <div class="selected d-center">{{ option.name }}</div>
            </template>
            <template #no-options>Ничего не найдено</template>
          </v-select>
          <template #error>
            <div v-if="$v.form.region.$dirty && !$v.form.region.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group required label="Адрес электронной почты" label-for="email">
          <app-input
            v-model="form.email"
            id="email"
            type="email"
            placeholder="Введите адрес электронной почты"
            :error="$v.form.email.$error"
            @change.native="$v.form.email.$touch()"
          />
          <template #error>
            <div v-if="$v.form.email.$dirty && !$v.form.email.email">Некорректный формат электронной почты</div>
            <div v-if="$v.form.email.$dirty && !$v.form.email.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group label="Телефон" required>
          <app-phone
            v-model="form.phone"
            :error="$v.form.phone.$dirty && (!$v.form.phone.required || (form.phone === 0))"
            @change.native="$v.form.phone.$touch()"
            @paste.native.prevent
          />
          <template #error>
            <div v-if="$v.form.phone.$dirty && !$v.form.phone.required">Обязательное поле</div>
            <div v-if="$v.form.phone.$dirty && (form.phone === 0) && $v.form.phone.required">
              Неправильный формат номера
            </div>
          </template>
        </app-form-group>
        <app-cells position="center">
          <app-button ref="submit" :disabled="$v.form.$error || (form.phone === 0)">Отправить</app-button>
        </app-cells>
      </form>
    </div>
    <div v-if="!hash_valid && !hash_validation_request" class="public-body__block public-body__block--center">
      <img src="@/assets/img/public/error.svg" alt="logo"/>
      <p class="public-body__text">Некорректная ссылка.</p>
    </div>
    <div v-if="submit_success" class="public-body__block public-body__block--center">
      <img src="@/assets/img/public/success-icon.svg" alt="logo"/>
      <p class="public-body__text">Ваша заявка успешно отправлена.</p>
    </div>
  </div>
</template>

<script>
import {between, required, email, minLength, maxLength} from 'vuelidate/lib/validators'
import {
  checkSecurityCategoriesHash,
  getAddress,
  getCitizenship,
  getCountry,
  getFMS,
  getGender,
  getRegions,
  getSmiTypes,
  postSmi
} from '@/http'
import {differenceInYears, parse} from 'date-fns'
import {debounce} from 'lodash'
import {dateFormatting} from '@/helpers'

const underscorePresent = value => {
  if (!value) return true
  else return !value.includes('_')
}

export default {
  name: 'SmiPublicCreate',
  data() {
    return {
      form: {
        passport_issued_by: '',
        adress_registration: '',
        crop: {},
      },
      member_full_year: null,
      birthday_age: 25,
      passport_age: 25,
      gender_options: [],
      citizenship_options: [],
      fms_options: [],
      address_options: [],
      country_options: [],
      smi_types: [],
      region_options: [],
      submit_success: false,
      hash_valid: false,
      hash_validation_request: true,
    }
  },
  validations() {
    const form = {
      first_name: { required },
      last_name: { required },
      gender: { required },
      citizenship: { required },
      passport_series: { required, minLength: minLength(this.seriesMinlength), maxLength: maxLength(this.seriesMaxlength)},
      passport_number: { required, minLength: minLength(this.numberMinlength), maxLength: maxLength(this.numberMaxlength)},
      birthday: { required, underscorePresent },
      passport_issued_date: { required, underscorePresent },
      passport_expiration_date: { underscorePresent },
      passport_issued_by: { required },
      adress_registration: { required },
      name: { required },
      smi_status: { required },
      region: { required },
      email: { required, email },
      phone: { required },
      position: { required },
      country: {},
    }

    if (this.form.citizenship === 2 || this.form.citizenship === 3) form.country = { required }

    return {
      form,
      birthday_age: { between: between(0, 90) },
      passport_age: { between: between(0, 90) },
    }
  },
  computed: {
    seriesMinlength() {
      if (this.form.citizenship === 1) return 4
      return 0
    },
    seriesMaxlength() {
      if (this.form.citizenship === 1) return 4
      return 100
    },
    numberMinlength() {
      if (this.form.citizenship === 1) return 6
      return 0
    },
    numberMaxlength() {
      if (this.form.citizenship === 1) return 6
      return 100
    },
  },
  created() {
    checkSecurityCategoriesHash(this.$route.params.securityHash)
      .then(() => {
        this.hash_valid = true
        getGender()
          .then(response => {
            this.gender_options = response.data
          })
        getCitizenship()
          .then(response => {
            this.citizenship_options = response.data
          })
        getCountry()
          .then(response => {
            this.country_options = response.data
          })
        getSmiTypes()
          .then(response => {
            this.smi_types = response.data
          })
        getRegions()
          .then(response => {
            this.region_options = response.data
          })
      })
      .catch(() => {
        this.hash_valid = false
      })
      .finally(() => {
        this.hash_validation_request = false
      })
  },
  methods: {
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        if (this.form.crop.coords && !this.form.crop.photo) {
          this.$refs.cropper.sendCrop().then(() => {
            this.form.photo_id = this.form.crop.photo
            this.form.coords = this.form.crop.coords
            this.sendForm()
          })
        } else {
          this.form.coords = this.form.crop.coords
          this.sendForm()
        }
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      postSmi(this.normalizeForm())
        .finally(() => {
          this.$refs.submit.preload = false
        })
        .then(() => {
          this.submit_success = true
        })
    },
    normalizeForm() {
      const form = {...this.form}
      if (form.citizenship === 1) delete form.country
      if (typeof form.adress_registration === 'object') {
        form.adress_registration_json = form.adress_registration
        form.adress_registration = form.adress_registration.address
      }
      if (typeof form.passport_issued_by === 'object') {
        form.passport_unit_code = form.passport_issued_by.code
        form.passport_issued_by = form.passport_issued_by.name
      }
      form.birthday = dateFormatting(form.birthday, 'normal-to-iso-small')
      form.passcode_hash = this.$route.params.securityHash
      delete form.crop
      return form
    },
    onCheckBirthdayDate() {
      this.$v.form.birthday.$touch()
      this.birthday_age = differenceInYears(new Date(), parse(this.form.birthday, 'dd.MM.yyyy', new Date()))
      if (!this.$v.form.birthday.$error) {
        this.member_full_year = differenceInYears(parse(`${process.env.VUE_APP_FESTIVAL_MIN_DATE}`, 'dd.MM.yyyy', new Date()), parse(this.form.birthday, 'dd.MM.yyyy', new Date()))
      }
    },
    onCheckPassportDate() {
      this.$v.form.passport_issued_date.$touch()
      this.passport_age = differenceInYears(new Date(), parse(this.form.passport_issued_date, 'dd.MM.yyyy', new Date()))
    },
    onChangeCitizenship() {
      this.form.passport_issued_by = ''
      this.form.adress_registration_json = ''
      this.form.adress_registration = ''
    },
    onFMSSearch(search, loading) {
      loading(true)
      this.searchFMS(loading, search, this)
    },
    searchFMS: debounce((loading, search, vm) => {
      getFMS(search).then(response => {
        vm.fms_options = response.data
        loading(false)
      })
    }, 350),
    onAddressSearch(search, loading) {
      loading(true)
      this.searchAddress(loading, search, this)
    },
    searchAddress: debounce((loading, search, vm) => {
      getAddress(search).then(response => {
        vm.address_options = response.data
        loading(false)
      })
    }, 350),
  }
}
</script>

<style scoped>

</style>
